<template>
    <div class="Favorite">
        <div class="top">
            <div class="flex-alc">
                <img src="@/assets/icon_sc@2x.png" class="img" alt="" />
                <div class="title">收藏夹</div>
            </div>
            <div class="crumbs">所在位置：个人中心 > 收藏夹</div>
        </div>
        <div class="flex-alc wrap">
            <NoData v-if="!curList.length"></NoData>
            <Curriculum
                v-for="(item, index) in curList"
                :key="'cur' + index"
                class="mb-20"
                :proess="true"
                :curriculum="item.curriculum"
                :progress="item.progress"
                :imgSize="218"
                :border="6"
                @cancel="cancel"
                :class="{ 'mr-17': (index + 1) % 4 != 0 }"
            ></Curriculum>
        </div>
        <div class="flex-jsc mt-32 mb-6">
            <Pagination
                :pageSize="8"
                :total="total"
                @getPage="getPage"
            ></Pagination>
        </div>
    </div>
</template>

<script>
    import NoData from "@/components/NoData.vue";
    import Curriculum from "@/components/Curriculum.vue";
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Favorite",
        components: {
            Curriculum,
            Pagination,
            NoData,
        },

        data() {
            return {
                curList: [],
                total: 0,
                page: 1,
            };
        },

        mounted() {
            this.getCur();
            window.localStorage.setItem("myNavActive", 1);
            this.$store.commit("switchMyNav", 1);
        },

        methods: {
            //取消收藏
            cancel(e) {
                this.$confirm("您确定要取消收藏吗？", "确认信息", {
                    distinguishCancelAndClose: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(() => {
                        this.$https
                            .post("/api/cur/collect", { id: e.id })
                            .then((res) => {
                                this.getCur(this.page);
                            });
                    })
                    .catch((action) => {
                        return false;
                    });
            },
            //分页
            getPage(e) {
                this.getCur(e);
                this.page = e;
            },
            //收藏列表
            getCur(page = 1) {
                this.$https
                    .get("/api/collect/list", { page, limit: 8 })
                    .then((res) => {
                        this.curList = res.data.list;
                        this.total = res.data.total_count;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.Favorite {
    width: 100%;
    background: #ffffff;
    border-radius: 15px;
    padding: 30px 23.5px;
    .top {
        padding-bottom: 24px;
        border-bottom: 1px solid #d5d5d5;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 30px;
        .img {
            width: 26px;
            height: 26px;
            margin-right: 10px;
        }
        .title {
            font-size: 18px;
            font-weight: 500;
            color: #2d2d2d;
        }
        .crumbs {
            font-size: 12px;
            font-weight: 400;
            color: #2d2d2d;
        }
    }
}
</style>